import * as React from "react"

const NotFoundPage = () => (
  <div className="page-404 px-sm pt-xl">
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
